<template>
  <div class="location-sublocation-table">
    <ui-loading
      v-if="isLoading"
      class="location-sublocation-table__loading"
      inline
    />
    <template v-else-if="locations.length">
      <div class="location-sublocation-table__row location-sublocation-table__row_header">
        <div class="location-sublocation-table__cell location-sublocation-table__cell_header" />
        <div
          v-for="column in columns"
          :key="column.id"
          class="location-sublocation-table__cell location-sublocation-table__cell_header"
        >
          {{ column.label }}
        </div>
      </div>
      <div class="location-sublocation-table__body">
        <ui-tooltip
          v-for="row in locations"
          :key="row.id"
          :disabled="!Boolean(locationDisabledInfo(row))"
          placement="bottom"
          :open-delay="500"
        >
          <template #content>
            <div class="location-sublocation-table__row-alert">
              <ui-icon
                name="card-status-error"
                size="18px"
                class="location-sublocation-table__row-alert-icon"
              />
              <div v-html="locationDisabledInfo(row)" />
            </div>
          </template>
          <div
            class="location-sublocation-table__row"
            title=""
            @click="selectSublocationHandler(row)"
          >
              <div class="location-sublocation-table__cell">
                <ui-radio
                  :label="row.id"
                  :value="String(selectedLocation.id)"
                  name="sublocation-row"
                  :disabled="Boolean(locationDisabledInfo(row))"
                  @input="selectSublocationHandler(row)"
                />
              </div>
              <div
                v-for="column in columns"
                :key="column.id"
                class="location-sublocation-table__cell"
                :class="{
                  'location-sublocation-table__cell_link': column.isLink,
                  'location-sublocation-table__cell_disabled': locationDisabledInfo(row),
                }"
              >
                {{ getValue(row, column) }}
              </div>
          </div>
        </ui-tooltip>
      </div>
    </template>
    <div v-else class="location-sublocation-table__no-data">
      {{ $t('noData') }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'SublocationSearchTable',

  model: {
    prop: 'selectedLocation',
    event: 'input',
  },

  props: {
    locations: {
      type: Array,
      required: true,
    },
    selectedLocation: {
      type: Object,
      default: () => {},
    },
    currentLocation: {
      type: Object,
      required: true,
    },
    currentSublocations: {
      type: Array,
      required: true,
    },
    isLoading: Boolean,
  },

  computed: {
    columns() {
      return [
        {
          id: 1,
          prop: 'payoutId',
          label: 'EasyTip ID',
          isLink: true,
        },
        {
          id: 2,
          prop: 'name',
          label: 'Name',
        },
        {
          id: 3,
          prop: 'createdWhen',
          label: 'Date',
        },
      ];
    },
  },

  methods: {
    getValue(row, column) {
      return row[column.prop];
    },
    locationDisabledInfo(row) {
      if (+row.id === +this.currentLocation.id) {
        return 'This location is the current master location';
      }
      if (this.currentSublocations.some((sublocation) => +sublocation.id === +row.id)) {
        return 'This location linked to current master location';
      }
      if (row.paymentPage === 'DIRECT') {
        return 'This location has direct main landing page';
      }
      // https://easytip.myjetbrains.com/youtrack/issue/EASYTIP-8136/Razreshit-ispolzovanie-Master-Location-kak-SubLocation
      // if (row.isMasterLocation) {
      //   return 'This location is a master location';
      // }
      if (row.parentId) {
        return 'This location linked to another master location';
      }
      return '';
    },
    selectSublocationHandler(row) {
      if (this.locationDisabledInfo(row)) {
        return;
      }
      this.$emit('input', row);
    },
  },
};
</script>
