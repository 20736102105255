<template>
  <validation-observer v-slot="{ handleSubmit }" tag="form" disabled>
    <ui-modal
      :show="show"
      v-bind="$attrs"
      v-on="$listeners"
      class="location-sublocation-modal"
      size="md"
      @before-close="reset"
    >
      <template #header>
        <div class="location-sublocation-modal__title">
          {{ $t('sublocation.addSublocation') }}
        </div>
      </template>
      <div class="location-sublocation-modal__body">
        <ui-input
          v-model="searchLocationsText"
          bordered
          :placeholder="$t('form.placeholders.locationNameOrId')"
          class="location-sublocation-modal__search-input"
        >
          <template #addonLeft>
            <ui-icon name="loupe" size="17" />
          </template>
        </ui-input>
        <location-sublocation-table
          v-model="form.sublocation"
          :locations="locations"
          :is-loading="isLoading"
          :current-location="location"
          :current-sublocations="sublocations"
          class="location-sublocation-modal__search-table"
        />
        <validation-provider
          tag="div"
          rules="required"
          mode="passive"
          v-slot="{ errors }"
          class="location-sublocation-modal__service-icons"
        >
          <location-service-type
            v-model="form.type"
            :type-list="sublocationTypeList"
            :label="$t('sublocation.selectIcon')"
            :error="errors[0]"
            class="is-filled"
            @toggle-icon="toggleType"
          />
        </validation-provider>
      </div>
      <template #footer="{ close }">
        <div class="location-sublocation-modal__footer">
          <ui-button
            action
            type="primary"
            size="sm"
            @click.prevent="handleSubmit(() => apply(close))"
          >
            {{ $t('form.buttons.apply') }}
          </ui-button>
        </div>
      </template>
    </ui-modal>
  </validation-observer>
</template>

<script>
import { debounce } from 'lodash';
import axios from 'axios';
import api from '@/api';
import LocationSublocationTable from '@/components/Location/LocationSublocationTable.vue';
import LocationServiceType from '@/components/Location/LocationServiceType.vue';
import resetMixin from '@/mixins/reset-mixin';

export default {
  name: 'LocationSublocationModal',
  components: {
    LocationSublocationTable,
    LocationServiceType,
  },

  mixins: [
    resetMixin(() => ({
      form: {
        type: null,
        sublocation: {},
      },
      searchLocationsText: '',
      locations: [],
      isLoading: false,
    })),
  ],

  model: {
    prop: 'show',
    event: 'input',
  },

  props: {
    location: {
      type: Object,
      required: true,
    },
    sublocations: {
      type: Array,
      required: true,
    },
    currentSublocation: {
      type: Object,
      default: null,
    },
    show: Boolean,
  },

  data() {
    return {
      searchHandle: null,
      sublocationTypeList: [
        {
          name: 'MANAGER',
          icon: require('@/assets/images/sublocations/manager.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.MANAGER'),
        },
        {
          name: 'WAITER',
          icon: require('@/assets/images/sublocations/waiter.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.WAITER'),
        },
        {
          name: 'HOUSE_KEEPING',
          icon: require('@/assets/images/sublocations/house-keeping.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.HOUSE_KEEPING'),
        },
        {
          name: 'CONCIERGE',
          icon: require('@/assets/images/sublocations/concierge.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.CONCIERGE'),
        },
        {
          name: 'VALET',
          icon: require('@/assets/images/sublocations/valet.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.VALET'),
        },
        {
          name: 'THERAPIST',
          icon: require('@/assets/images/sublocations/therapist.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.THERAPIST'),
        },
        {
          name: 'RECEPTION',
          icon: require('@/assets/images/sublocations/reception.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.RECEPTION'),
        },
        {
          name: 'ATTENDANT',
          icon: require('@/assets/images/sublocations/attendant.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.ATTENDANT'),
        },
        {
          name: 'BAR',
          icon: require('@/assets/images/groups/spa-bar.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.BAR'),
        },
        {
          name: 'BEAUTICIAN',
          icon: require('@/assets/images/groups/spa-beautician.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.BEAUTICIAN'),
        },
        {
          name: 'CAFE',
          icon: require('@/assets/images/groups/spa-cafe.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.CAFE'),
        },
        {
          name: 'FITNESS',
          icon: require('@/assets/images/groups/spa-fitness.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.FITNESS'),
        },
        {
          name: 'NAILS',
          icon: require('@/assets/images/groups/spa-nails.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.NAILS'),
        },
        {
          name: 'SALON',
          icon: require('@/assets/images/groups/spa-salon.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.SALON'),
        },
        {
          name: 'SAUNA',
          icon: require('@/assets/images/groups/spa-sauna.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.SAUNA'),
        },
        {
          name: 'YOGA',
          icon: require('@/assets/images/groups/spa-yoga.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.YOGA'),
        },
        {
          name: 'WELLNESS',
          icon: require('@/assets/images/groups/spa-wellness.svg'), // eslint-disable-line global-require
          label: this.$t('sublocation.types.WELLNESS'),
        },
      ],
    };
  },

  computed: {
    locationsSearchParams() {
      let searchParams;

      if (!this.searchLocationsText) {
        searchParams = null;
      } else if (!Number.isNaN(+this.searchLocationsText)) {
        searchParams = {
          payoutId: this.searchLocationsText,
        };
      } else {
        searchParams = {
          locationName: this.searchLocationsText,
        };
      }

      return searchParams;
    },
  },

  methods: {
    apply(closeCallback) {
      if (this.form.sublocation && this.form.type) {
        const newSublocation = {
          id: +this.form.sublocation.id,
          name: this.form.sublocation.name,
          payoutId: this.form.sublocation.payoutId,
          subLocationType: this.form.type,
        };
        if (this.currentSublocation) {
          const editingSublocationIndex = this.sublocations.findIndex((sublocation) => sublocation.id === this.currentSublocation.id);
          this.sublocations.splice(editingSublocationIndex, 1, newSublocation);
        } else {
          this.sublocations.push(newSublocation);
        }
        closeCallback();
      }
    },
    toggleType(name) {
      this.form.type = name;
    },
    loadLocations: debounce(async function loadLocationsDebounced() {
      if (this.searchHandle) {
        this.searchHandle.cancel();
      }

      this.searchHandle = api.notifications.getLocations({
        country: this.location.country,
        ...this.locationsSearchParams,
      });

      try {
        const { data } = await this.searchHandle;

        if (data) {
          this.locations = data.data;
        }
      } catch (e) {
        if (!axios.isCancel(e)) {
          this.$showServerError(e);
        }
      } finally {
        this.searchHandle = null;
        this.isLoading = false;
      }
    }, 500),
  },

  watch: {
    searchLocationsText(newText) {
      if (!newText) {
        this.locations = [];
      } else {
        this.isLoading = true;
        this.loadLocations();
      }
    },
    currentSublocation(sublocation) {
      if (sublocation) {
        this.form = {
          type: sublocation.subLocationType,
          sublocation,
        };
        this.locations = [sublocation];
      } else {
        this.reset();
      }
    },
  },
};
</script>
